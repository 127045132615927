<script setup>
import { useViewEditInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/viewEditInfoInventory.store'
import { storeToRefs } from 'pinia'
import { useStore } from '@/store'
import Icon from '@/components/Icon/index.vue'
import { ref, computed } from 'vue'
import InventoryService from '@/apps/dailyTech/inventario-ti/services/inventory.service'
import dayjs from 'dayjs'

const store = useStore()
const viewEditInfoInventoryStore = useViewEditInfoInventoryStore()
const { _showRightSidebar, _viewEditInfoInventory } = storeToRefs(viewEditInfoInventoryStore)
const _InventoryService = ref(new InventoryService())
const visibleInfoInventory = ref(true)
const visibleAttributesInventory = ref(true)

// Funcion para mostrar u ocultar la información del registro
const onVisibleInfoInventory = () => {
  visibleInfoInventory.value = !visibleInfoInventory.value
}

// Funcion para mostrar u ocultar los atributos del registro
const onVisibleAttributesInventory = () => {
  visibleAttributesInventory.value = !visibleAttributesInventory.value
}

// Obtener la imagen del registro inventario
const getImageInventory = (image) => {
  // Detectar si la imagen es object
  if (typeof image === 'object') {
    return URL.createObjectURL(image)
  }

  const token = store.getters['auth/getToken']
  const obj = { image, token }
  return _InventoryService.value.getImageInventory(obj)
}

// Establecer el valor de los atributos del registro
const fieldAttributeValues = computed(() => {
  return _viewEditInfoInventory.value[0].fieldsAttributes.map(fieldAttribute => {
    if (Array.isArray(fieldAttribute.value) && fieldAttribute.value.length > 0) {
      return fieldAttribute.value.join(', ')
    } else if (!Array.isArray(fieldAttribute.value) && fieldAttribute.value) {
      return fieldAttribute.dataType === 'date' ? dayjs(fieldAttribute.value).format('YYYY-MM-DD') : fieldAttribute.value
    } else {
      return 'Sin asignar'
    }
  })
})
</script>

<template>
  <div class="box h-full" :class="{'hidden': !_showRightSidebar}">
    <div class="mt-0 flex flex-col" style="height: 892px;padding-bottom: 16px;">
      <!-- Titulo del sidebar -->
      <div style="height: 63px;" class="flex px-4 py-6 items-center border-b border-gray-200 dark:border-dark-5">
        <h3 class="mr-auto text-base font-medium">Ver registro: <span class="text-blue-900 font-bold">{{ _viewEditInfoInventory[0].attributes.nombre ?? 'Sin nombre' }}</span></h3>
      </div>

      <!-- Contenido -->
      <div class="grid grid-cols-12 gap-4 py-4 px-8 overflow-y-auto">

        <!-- Información relevante del dispositivo -->
        <div class="col-span-12 sm:col-span-12 md:col-span-12">
          <div class="flex flex-wrap flex-col justify-center items-center">
            <div class="flex justify-center items-center bg-gray-200 rounded-md" style="width: 150px; height: 150px">
              <Image
                v-if="_viewEditInfoInventory[0].photo"
                :src="getImageInventory(_viewEditInfoInventory[0].photo)"
                alt="Image"
                preview
                :pt="{
                  root: { class: 'flex justify-center items-center w-full h-full bg-gray-200 rounded-md' },
                  image: { style: 'width: 150px; height: 150px; object-fit: contain;' }
                }"
              />
              <Icon v-else :icon="_viewEditInfoInventory[0].typeDeviceIcon ? _viewEditInfoInventory[0].typeDeviceIcon : 'material-symbols:devices-off-outline-rounded'" style="opacity: 0.4; font-size: 8rem" />
            </div>
            <span class="text-gray-500 text-lg font-semibold">{{ _viewEditInfoInventory[0].attributes.nombre ?? '(Sin nombre)' }}</span>
            <span class="text-gray-600 text-base font-bold">{{ _viewEditInfoInventory[0].typeDeviceName }}</span>
          </div>
        </div>

        <!-- Información del registro -->
        <div class="col-span-12 lg:col-span-12">
          <Divider
            align="left"
            type="dashed"
            :pt="{
              root: { style: 'margin: 7px 0 7px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-sm font-semibold cursor-pointer text-blue-900" @click="onVisibleInfoInventory">Información del registro</span>
          </Divider>
        </div>

        <div class="col-span-12" v-show="visibleInfoInventory">
          <div class="flex mb-3">
            <div class="flex flex-wrap items-center">
              <Icon
                :icon="_viewEditInfoInventory[0].statusIcon"
                class="text-3xl text-green-700 font-bold py-1 px-4"
              />
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Estado:</span>
                <span class="text-gray-500 text-sm font-semibold">{{ _viewEditInfoInventory[0].statusName ?? 'Sin asignar' }}</span>
              </div>
            </div>
          </div>
          <div class="flex mb-3">
            <div class="flex flex-wrap items-center">
              <Icon
                icon="mdi:map-marker"
                class="text-3xl text-red-700 font-bold py-1 px-4"
              />
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Ubicación:</span>
                <span class="text-gray-500 text-sm font-semibold">{{ _viewEditInfoInventory[0].locationName ?? 'Sin asignar' }}</span>
              </div>
            </div>
          </div>
          <div class="flex mb-3">
            <div class="flex flex-wrap items-center">
              <Icon
                icon="mdi:account"
                class="text-3xl text-yellow-700 font-bold py-1 px-4"
              />
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Dueño:</span>
                <span class="text-gray-500 text-sm font-semibold">{{ _viewEditInfoInventory[0].ownerName ?? 'Sin asignar' }}</span>
              </div>
            </div>
          </div>
          <div class="flex mb-3">
            <div class="flex flex-wrap items-center">
              <Icon
                icon="mdi:registered-trademark"
                class="text-3xl text-gray-700 font-bold py-1 px-4"
              />
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Marca:</span>
                <span class="text-gray-500 text-sm font-semibold">{{ _viewEditInfoInventory[0].brandName ?? 'Sin asignar' }}</span>
              </div>
            </div>
          </div>
          <div class="flex mb-3">
            <div class="flex flex-wrap items-center">
              <Icon
                icon="mdi:devices"
                class="text-3xl text-blue-700 font-bold py-1 px-4"
              />
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Modelo:</span>
                <span class="text-gray-500 text-sm font-semibold">{{ _viewEditInfoInventory[0].modelName ?? 'Sin asignar' }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Atributos del registro -->
        <div class="col-span-12 lg:col-span-12">
          <Divider
            align="left"
            type="dashed"
            :pt="{
              root: { style: 'margin: 7px 0 7px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-sm font-semibold cursor-pointer text-blue-900" @click="onVisibleAttributesInventory">Atributos del registro</span>
          </Divider>
        </div>

        <div class="col-span-12" v-show="visibleAttributesInventory">
          <div
            v-for="(fieldAttribute, index) in _viewEditInfoInventory[0].fieldsAttributes"
            :key="index"
            class="flex mb-3"
          >
            <div class="flex items-start">
              <Icon
                icon="mdi:feature-search"
                class="text-3xl text-gray-400 font-bold py-1 px-4"
              />
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">{{ fieldAttribute.showName }}:</span>
                <span
                  :class="(Array.isArray(fieldAttribute.value) && fieldAttribute.value.length > 0) || (!Array.isArray(fieldAttribute.value) && fieldAttribute.value) ? 'text-gray-500 text-sm font-semibold' : 'text-sm text-yellow-800 font-light italic opacity-50'"
                >
                  {{ fieldAttributeValues[index] }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>

</style>
