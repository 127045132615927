<script setup>
import { defineProps, ref } from 'vue'
import Swal from 'sweetalert2'
import InventoryService from '@/apps/dailyTech/inventario-ti/services/inventory.service'
import { useToast } from 'primevue/usetoast'
import { useViewEditInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/viewEditInfoInventory.store'
import { useFiltersInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/filtersInventory.store'

const viewEditInfoInventoryStore = useViewEditInfoInventoryStore()
const filtersInventoryStore = useFiltersInventoryStore()
const toast = useToast()
const _InventoryService = ref(new InventoryService())
const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})

// Funcion para eliminar un registro de inventario
const onDeleteInventory = (data) => {
  try {
    const { id } = data

    if (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: `¿Esta seguro de eliminar el registro de inventario con ID #${id}?, esta acción no se puede revertir.`,
        showDenyButton: true,
        denyButtonText: 'No, Cancelar',
        confirmButtonText: 'Si, Eliminar',
        focusDeny: true,
        input: 'textarea',
        inputPlaceholder: 'Ingrese la observación del porque se elimina...',
        inputAttributes: {
          'aria-label': 'Observación'
        },
        inputValue: '',
        inputValidator (inputValue) {
          if (!inputValue) {
            return 'Debe ingresar una observación para poder eliminar el registro de inventario'
          }
        }
      }).then((result) => {
        console.log('Result -->', result)
        if (result.isConfirmed) {
          _InventoryService.value.deleteInventory(id, { deletedObservation: result.value }).then(({ status }) => {
            if (status !== 200) return

            toast.add({ severity: 'success', summary: 'Exito', detail: 'Registro de inventario eliminado exitosamente', life: 3000 })
            filtersInventoryStore.setRefrescarListadoInventory(true)
            viewEditInfoInventoryStore._actionClearViewEditInfoInventory()
          }).catch((err) => {
            console.error(err.message)
            if (err.response.status) {
              toast.add({ severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar el registro de inventario', life: 3000 })
            }
          })
        }
      })
    } else {
      toast.add({ severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar el registro de inventario', life: 3000 })
    }
  } catch (error) {
    console.error(error.message)
  }
}
</script>

<template>
  <div class="flex w-full justify-center items-center">
    <Button
      icon="pi pi-trash"
      severity="danger"
      size="small"
      @click="onDeleteInventory(props.data)"
      rounded
      outlined
      v-tippy="{ content: 'Eliminar registro' }"
    />
  </div>
</template>

<style scoped>

</style>
