import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_INVENTARIO_TI

export default class LocationsService {
  // Obtener las ubicaciones
  getLocations (params) {
    return http.get(`${baseUrl}/location/listar-ubicaciones`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  // Crear una ubicacion
  createLocation (body) {
    return http.post(`${baseUrl}/location/crear-ubicacion`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Editar una ubicacion
  editLocation (id, body) {
    return http.put(`${baseUrl}/location/editar-ubicacion/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Eliminar una ubicacion
  deleteLocation (id) {
    return http.delete(`${baseUrl}/location/eliminar-ubicacion/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
