<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { Icon } from '@iconify/vue'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  column: {
    type: String,
    default: ''
  }
})
const router = useRouter()

// Funcion que redirige a la vista de perfil de inventario
const onProfileInventory = (data) => {
  router.push({
    name: 'daily-tech.inventario-ti.perfil.id',
    params: {
      id: data.id
    }
  })
}

</script>

<template>
  <div class="flex w-full">
    <Button
      severity="secondary"
      text
      size="small"
      style="height: 35px"
      class="mr-2"
      @click="onProfileInventory(props.data)"
      v-tippy="{ content: 'Ver perfil de inventario' }"
    >
      <div class="flex flex-col justify-center items-center w-full">
        <Icon
          icon="ph:read-cv-logo-duotone"
          class="text-base"/>
        <span class="text-xs text-center underline font-bold">{{ props.data.id }}</span>
      </div>
    </Button>
  </div>
</template>

<style scoped>

</style>
