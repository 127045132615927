import { defineStore } from 'pinia'

import DeviceService from '@/apps/dailyTech/inventario-ti/services/devices.service'
import BrandService from '@/apps/dailyTech/inventario-ti/services/brands.service'
import ModelsService from '@/apps/dailyTech/inventario-ti/services/models.service'
import LocationsService from '@/apps/dailyTech/inventario-ti/services/locations.service'
import OwnersService from '@/apps/dailyTech/inventario-ti/services/owners.service'
import StatusService from '@/apps/dailyTech/inventario-ti/services/status.service'
import FieldsService from '@/apps/dailyTech/inventario-ti/services/fields.service'

const _DeviceService = new DeviceService()
const _BrandService = new BrandService()
const _ModelsService = new ModelsService()
const _LocationsService = new LocationsService()
const _OwnersService = new OwnersService()
const _StatusService = new StatusService()
const _FieldsService = new FieldsService()

export const useFiltersInventoryStore = defineStore({
  id: 'filtersInventory',
  state: () => ({
    _filtersApply: [],
    _listTypeDevices: [],
    _listBrands: [],
    _listModels: [],
    _listDepartamentos: [],
    _listMunicipios: [],
    _listLocations: [],
    _listOwners: [],
    _listStatus: [],
    _buscarListadoInventory: false,
    _refrescarListadoInventory: false,
    _limpiarListadoInventory: false,
    _showHideFilters: true,
    _filtersAdvanced: [{
      nameField: null,
      condition: null,
      showName: null,
      dataType: null,
      value: []
    }],
    _listFiltersFieldsGeneral: [
      { nameField: 'typeDevice', showName: 'Tipo de dispositivo', choiceList: [], dataType: 'select', isAttribute: false },
      { nameField: 'brand', showName: 'Marca', choiceList: [], dataType: 'select', isAttribute: false },
      { nameField: 'model', showName: 'Modelo', choiceList: [], dataType: 'select', isAttribute: false },
      { nameField: 'locationDepto', showName: 'Departamento', choiceList: [], dataType: 'select', isAttribute: false },
      { nameField: 'locationCity', showName: 'Municipio', choiceList: [], dataType: 'select', isAttribute: false },
      { nameField: 'location', showName: 'Ubicación', choiceList: [], dataType: 'select', isAttribute: false },
      { nameField: 'owner', showName: 'Propietario', choiceList: [], dataType: 'select', isAttribute: false },
      { nameField: 'status', showName: 'Estado', choiceList: [], dataType: 'select', isAttribute: false }
    ],
    _listFiltersFieldsAttributes: [],
    _listFiltersFieldsAll: [],
    _listFiltersConditions: [
      {
        dataType: 'text',
        conditions: [
          { value: 'contains', name: 'Contiene' },
          { value: 'notContains', name: 'No contiene' },
          { value: 'equals', name: 'Igual a' },
          { value: 'notEquals', name: 'Diferente de' }
        ]
      },
      {
        dataType: 'number',
        conditions: [
          { value: 'equals', name: 'Igual a' },
          { value: 'notEquals', name: 'Diferente de' },
          { value: 'greaterThan', name: 'Mayor que' },
          { value: 'lessThan', name: 'Menor que' }
        ]
      },
      {
        dataType: 'date',
        conditions: [
          { value: 'equals', name: 'Igual a' },
          { value: 'notEquals', name: 'Diferente de' },
          { value: 'greaterThan', name: 'Mayor que' },
          { value: 'lessThan', name: 'Menor que' }
        ]
      },
      {
        dataType: 'select',
        conditions: [
          { value: 'equals', name: 'Igual a' },
          { value: 'notEquals', name: 'Diferente de' },
          { value: 'in', name: 'estan en la lista' },
          { value: 'notIn', name: 'no estan en la lista' }
        ]
      }
    ]
  }),
  getters: {
    getFiltersApply: (state) => state._filtersApply,
    getListTypeDevices: (state) => state._listTypeDevices,
    getListBrands: (state) => state._listBrands,
    getListModels: (state) => state._listModels,
    getListDepartamentos: (state) => state._listDepartamentos,
    getListMunicipios: (state) => state._listMunicipios,
    getListLocations: (state) => state._listLocations,
    getListOwners: (state) => state._listOwners,
    getListStatus: (state) => state._listStatus,
    getListFiltersFieldsGeneral: (state) => state._listFiltersFieldsGeneral,
    getListFiltersFieldsAttributes: (state) => state._listFiltersFieldsAttributes,
    getListFiltersFieldsAll: (state) => state._listFiltersFieldsAll,
    getListFiltersConditions: (state) => state._listFiltersConditions,
    getFiltersAdvanced: (state) => state._filtersAdvanced,
    getBuscarListadoInventory: (state) => state._buscarListadoInventory,
    getRefrescarListadoInventory: (state) => state._refrescarListadoInventory,
    getLimpiarListadoInventory: (state) => state._limpiarListadoInventory
  },
  actions: {
    setListTypeDevices (payload) {
      this._listTypeDevices = payload
    },
    setListBrands (payload) {
      this._listBrands = payload
    },
    setListModels (payload) {
      this._listModels = payload
    },
    setListDepartamentos (payload) {
      this._listDepartamentos = payload
    },
    setListMunicipios (payload) {
      this._listMunicipios = payload
    },
    setListLocations (payload) {
      this._listLocations = payload
    },
    setListOwners (payload) {
      this._listOwners = payload
    },
    setListStatus (payload) {
      this._listStatus = payload
    },
    setListFiltersFieldsGeneral (payload) {
      this._listFiltersFieldsGeneral = payload
    },
    setListFiltersFieldsAttributes (payload) {
      this._listFiltersFieldsAttributes = payload
    },
    setListFiltersFieldsAll (payload) {
      this._listFiltersFieldsAll = payload
    },
    setListFiltersConditions (payload) {
      this._listFiltersConditions = payload
    },
    setBuscarListadoInventory (payload) {
      this._buscarListadoInventory = payload
    },
    setRefrescarListadoInventory (payload) {
      this._refrescarListadoInventory = payload
    },
    setLimpiarListadoInventory (payload) {
      this._limpiarListadoInventory = payload
    },
    setFiltersApply (payload) {
      this._filtersApply = payload
    },
    actionClearFilters () {
      this._filtersAdvanced = [{
        nameField: null,
        condition: null,
        showName: null,
        dataType: null,
        value: []
      }]
      this._filtersApply = []
    },
    async _actionFilterTypeDevice () {
      try {
        const { data } = await _DeviceService.getDevices()
        this.setListTypeDevices(data)
      } catch (error) {
        console.log('Error en _actionFilterTypeDevice', error)
      }
    },
    async _actionFilterBrands () {
      try {
        const { data } = await _BrandService.getBrands()
        this.setListBrands(data)
      } catch (error) {
        console.log('Error en _actionFilterBrands', error)
      }
    },
    async _actionFilterModels (payload) {
      try {
        const { data } = await _ModelsService.getModels({ idbrand: payload || null })
        this.setListModels(data)
      } catch (error) {
        console.log('Error en _actionFilterModels', error)
      }
    },
    async _actionFilterLocations () {
      try {
        const { data } = await _LocationsService.getLocations()

        // Recorrer data para crear un array de objetos con label y value de departamentos y municipios y asignarlos a _listDepartamentos y _listMunicipios
        const departamentos = []
        const municipios = []
        data.forEach(item => {
          // Obtener valores unicos de departamentos y asignarle objeto label value con el mismo valor de departamento
          if (!departamentos.includes(item.depto)) {
            departamentos.push({ label: item.depto, value: item.depto })
          }
          // Obtener valores unicos de municipios y asignarle objeto label value con el mismo valor de municipio
          if (!municipios.includes(item.ciudad)) {
            municipios.push({ label: item.ciudad, value: item.ciudad })
          }
        })

        // Obtener valores unicos de depto y municipios
        const uniqueDepto = departamentos.filter((item, index, self) =>
          index === self.findIndex(t => t.value === item.value)
        )
        const uniqueMunicipios = municipios.filter((item, index, self) =>
          index === self.findIndex(t => t.value === item.value)
        )

        this.setListDepartamentos(uniqueDepto)
        this.setListMunicipios(uniqueMunicipios)

        this.setListLocations(data)
      } catch (error) {
        console.log('Error en _actionFilterLocations', error)
      }
    },
    async _actionFilterOwners () {
      try {
        const { data } = await _OwnersService.getOwners()
        this.setListOwners(data)
      } catch (error) {
        console.log('Error en _actionFilterOwners', error)
      }
    },
    async _actionFilterStatus () {
      try {
        const { data } = await _StatusService.getStatus()
        this.setListStatus(data)
      } catch (error) {
        console.log('Error en _actionFilterStatus', error)
      }
    },
    async _actionFilterFields () {
      try {
        const { data } = await _FieldsService.getFields()
        // Agregar a data un campo adicional llamado isAttribute con valor true
        data.forEach(item => {
          item.isAttribute = true
        })
        this.setListFiltersFieldsAttributes(data)
      } catch (error) {
        console.log('Error en _actionFilterFields', error)
      }
    }
  }
})
