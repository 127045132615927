import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_INVENTARIO_TI

export default class ModelsService {
  // Obtener los modelos
  getModels (params) {
    return http.get(`${baseUrl}/model/listar-modelos`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  // Crear un modelo
  createModel (body) {
    return http.post(`${baseUrl}/model/crear-modelo`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Editar un modelo
  editModel (id, body) {
    return http.put(`${baseUrl}/model/editar-modelo/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Eliminar un modelo
  deleteModel (id) {
    return http.delete(`${baseUrl}/model/eliminar-modelo/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  // Ver imagen
  getImageModel (params) {
    const { image, token } = params
    return `${baseUrl}/model/ver-imagen/${image}?token=${token}`
  }
}
