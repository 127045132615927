<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  column: {
    type: String,
    default: ''
  }
})

// Obtener el valor de la columna attributes.
const valueColumn = computed(() => {
  const keys = props.column.split('.')
  let value = props.data
  keys.forEach(key => {
    if (value && key in value) {
      value = value[key]
    } else {
      value = null
    }
  })
  return value
})

</script>

<template>
  <span>{{ valueColumn }}</span>
</template>

<style scoped>

</style>
