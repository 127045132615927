<template>
  <Toast />
  <div>
    <file-pond
      name="test"
      ref="pond"
      label-idle="Arrastra y suelta tus archivos o <span class='filepond--label-action'> Navega </span>"
      credits="false"
      maxFileSize="5MB"
      labelMaxFileSizeExceeded="El archivo es demasiado grande"
      labelMaxFileSize="El tamaño máximo del archivo es de 5mb"
      :allow-multiple="false"
      accepted-file-types="image/jpeg, image/png"
      instantUpload="false"
      @init="handleFilePondInit"
      @addfile="handleFilePondAddFile"
      @initfile="handleFilePondInitFile"
      @removefile="handleFilePondRemoveFile"
      labelTapToCancel="Toca para cancelar"
      labelButtonAbortItemLoad = "Cancelar descarga"
      labelButtonRemoveItem = "Borrar archivo"
      labelButtonRetryItemLoad="Cargando"
      labelButtonRetryItemProcessing ="Reintentar"
      labelButtonUndoItemProcessing ="Deshacer"
      labelDecimalSeparator =","
      labelFileAdded = "Archivo Añadido"
      labelFileCountPlural ="Archivos"
      labelFileCountSingular ="Archivo"
      labelFileLoadError ="Error al subir"
      labelFileLoading ="Cargando"
      labelFileProcessing ="Subiendo"
      labelFileProcessingAborted ="Subida cancelada"
      labelFileProcessingComplete ="Subida completa"
      labelFileProcessingError ="Error al subir archivo"
      labelFileRemoved="Archivo eliminado"
      labelFileSizeNotAvailable ="Tamaño no disponible"
      labelFileWaitingForSize ="Comprobando tamaño"
      labelIdle ="Arrastre un archivo o pulse aqui"
      labelTapToRetry="Toca para reintentar"
      labelTapToUndo="Toca para deshacer"
      labelThousandsSeparator=""
    />
  </div>

</template>

<script>
import { ref } from 'vue'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview'
import FilePondPluginValidateSize from 'filepond-plugin-file-validate-size'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginPdfPreview,
  FilePondPluginValidateSize
)

export default {
  name: 'filePondUpload',
  emits: ['captureFileExist', 'removeFileExist', 'captureFileNew', 'removeFileNew', 'initFilePondExist', 'initFilePondNew'],
  components: {
    FilePond
  },
  props: {
    typeFilePond: {
      type: String
    }
  },
  setup (props, context) {
    const pond = ref({})

    const handleFilePondInit = () => {
      context.emit(props.typeFilePond === 'existModel' ? 'initFilePondExist' : 'initFilePondNew')
    }

    const handleFilePondAddFile = (error, file) => {
      console.log('Type Filepond: ', props.typeFilePond)
      if (!error) {
        context.emit(props.typeFilePond === 'existModel' ? 'captureFileExist' : 'captureFileNew', file.file)
      }
    }

    const handleFilePondRemoveFile = (error, file) => {
      if (!error) {
        context.emit(props.typeFilePond === 'existModel' ? 'removeFileExist' : 'removeFileNew', file.file)
      }
    }

    const handleFilePondInitFile = () => {
      context.emit(props.typeFilePond === 'existModel' ? 'initFileExist' : 'initFileNew')
    }

    const onRemoveSupport = async () => {
      console.log('Entre aqui...')
      return pond.value.removeFiles()
    }

    return {
      handleFilePondInit,
      handleFilePondInitFile,
      handleFilePondAddFile,
      handleFilePondRemoveFile,
      onRemoveSupport,
      pond
    }
  }

}
</script>

<style>
</style>
