import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_INVENTARIO_TI

export default class FieldsService {
  // Obtener los campos
  getFields (params) {
    return http.get(`${baseUrl}/field/listar-campos`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  // Obtener los campos por dispositivo
  getFieldsByDevice (params) {
    return http.get(`${baseUrl}/field/listar-por-dispositivo/${params.id}`, {
      headers: {
        loading: true
      }
    })
  }

  // Crear un campo
  createField (body) {
    return http.post(`${baseUrl}/field/crear-campo`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Editar un campo
  editField (id, body) {
    return http.put(`${baseUrl}/field/editar-campo/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Eliminar un campo
  deleteField (id) {
    return http.delete(`${baseUrl}/field/eliminar-campo/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
