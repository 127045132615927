<script setup>
import { onMounted, reactive, ref, watch } from 'vue'
import InventoryService from '@/apps/dailyTech/inventario-ti/services/inventory.service'
import Icon from '@/components/Icon/index.vue'
import { useFiltersInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/filtersInventory.store'
import { useViewEditInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/viewEditInfoInventory.store'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { error } from '@/libs/mensajes'
import SidebarAdvancedFilters from '@/apps/dailyTech/inventario-ti/views/list/components/sidebarAdvancedFilters.vue'
import ColumnTemplatePhoto from '@/apps/dailyTech/inventario-ti/views/list/table/components/columnTemplatePhoto.vue'
import ColumnTemplateDefault from '@/apps/dailyTech/inventario-ti/views/list/table/components/columnTemplateDefault.vue'
import ColumnTemplateTypeDevice from '@/apps/dailyTech/inventario-ti/views/list/table/components/columnTemplateTypeDevice.vue'
import ColumnTemplateAttributes from '@/apps/dailyTech/inventario-ti/views/list/table/components/columnTemplateAttributes.vue'
import ColumnTemplateLocation from '@/apps/dailyTech/inventario-ti/views/list/table/components/columnTemplateLocation.vue'
import ColumnTemplateStatus from '@/apps/dailyTech/inventario-ti/views/list/table/components/columnTemplateStatus.vue'
import ColumnTemplateActionsInitial from '@/apps/dailyTech/inventario-ti/views/list/table/components/columnTemplateActionsInitial.vue'
import ColumnTemplateActionsEnd from '@/apps/dailyTech/inventario-ti/views/list/table/components/columnTemplateActionsEnd.vue'

const filtersInventoryStore = useFiltersInventoryStore()
const {
  _filtersAdvanced,
  _filtersApply,
  _buscarListadoInventory,
  _refrescarListadoInventory,
  _limpiarListadoInventory,
  _listFiltersFieldsAll,
  _listFiltersConditions
} = storeToRefs(filtersInventoryStore)
const viewEditInfoInventoryStore = useViewEditInfoInventoryStore()
const { _viewEditInfoInventory, _showRightSidebar, _editRightSidebar } = storeToRefs(viewEditInfoInventoryStore)
const route = useRoute()
const router = useRouter()
const toast = useToast()
const limit = ref(20)
const offset = ref(0)
const orderField = ref('id')
const sortOrder = ref(-1)
const _InventoryService = ref(new InventoryService())
const listInventory = ref([])
const selectedInventory = ref([])
const refSidebarAdvancedFilters = ref(null)
const filtersApplyContent = ref('')
const filtersUrl = ref([])
const listColumns = ref([])
const selectedColumns = ref([])
const columnComponents = reactive({
  id: ColumnTemplateActionsInitial,
  photo: ColumnTemplatePhoto,
  typeDeviceName: ColumnTemplateTypeDevice,
  locationName: ColumnTemplateLocation,
  statusName: ColumnTemplateStatus,
  attributes: ColumnTemplateAttributes,
  actions: ColumnTemplateActionsEnd
})

// Funcion para el toggle de mostrar columnas en la tabla
const onToggle = (val) => {
  console.log('onToggle', val)
  selectedColumns.value = listColumns.value.filter((item) => {
    console.log('item', item)
    return val.some(v => v.field === item.field)
  })

  // Guardar la configuración de las columnas en localStorage
  localStorage.setItem('selectedColumns', JSON.stringify(selectedColumns.value))
}

// Funcion para reordenar las columnas
const onColReorder = (event) => {
  const dragIndex = event.dragIndex - 1
  const dropIndex = event.dropIndex - 1

  // Actualizar el orden de las columnas en selectedColumns
  const draggedColumn = selectedColumns.value.splice(dragIndex, 1)[0]

  if (draggedColumn) {
    selectedColumns.value.splice(dropIndex, 0, draggedColumn)
  }

  // Guardar la configuración de las columnas en localStorage
  localStorage.setItem('selectedColumns', JSON.stringify(selectedColumns.value))
}

// Abrir el sidebar de añadir filtros
const onSidebarAdvancedFilters = (data) => {
  refSidebarAdvancedFilters.value.openSidebarAdvancedFilters(data)
  selectedInventory.value = []
}

// Función que permite la actualización de parámetros de consulta de manera dinámica antes de realizar la navegación a una nueva URL.
const _routerPush = (obj = {}) => {
  const queryParams = route.query
  router.push({ query: { ...queryParams, ...obj } })
}

// Ordenar por campo
const orderQuery = ({ sortField, sortOrder }) => {
  _routerPush({ order: `${sortField},${sortOrder}` })
}

// Paginación
const onPage = (row) => {
  const queryParams = { ...route.query }
  limit.value = row.rows
  offset.value = row.first

  // _routerPush({ ...queryParams, limit: row.rows, offset: row.first })
  router.push({ query: { ...queryParams, limit: row.rows, offset: row.first } })
}

// Asignacion de los query params
const setQueryParams = () => {
  const queryParams = { ...route.query }
  limit.value = queryParams.limit ? parseInt(queryParams.limit) : 20
  offset.value = queryParams.offset ? parseInt(queryParams.offset) : 0
  queryParams.limit = queryParams.limit ? queryParams.limit : 20
  queryParams.offset = queryParams.offset ? queryParams.offset : 0
  queryParams.order = queryParams.order ? queryParams.order : 'id,-1'

  // Split a la cadena para obtener el campo y el orden
  const splitOrder = queryParams.order.split(',')
  orderField.value = splitOrder[0]
  sortOrder.value = parseInt(splitOrder[1])

  return queryParams
}

// Funcion para obtener el listado de inventario
const fetchListInventory = async () => {
  try {
    const response = await _InventoryService.value.getListInventory(setQueryParams())
    listInventory.value = response.data
    listColumns.value = response.data.columns
    // Agregar la columna de acciones al final
    listColumns.value.push({ field: 'actions', header: 'Acciones' })
  } catch (error) {
    console.error('Error al obtener el listado de inventario --> ', error)
  }
}

// TODO: Función para seleccionar mas de una fila
// const onRowSelect = (event) => {
//   if (selectedInventory.value.length > 0) {
//     const { data } = event
//
//     viewEditInfoInventoryStore._actionViewEditInfoInventory(data)
//   }
// }

// Función para seleccionar una fila
const onRowSelect = (event) => {
  // Si ya hay una fila seleccionada, se quita la selección
  if (selectedInventory.value.length > 0) {
    selectedInventory.value = []
    viewEditInfoInventoryStore._actionClearViewEditInfoInventory()
  }

  const { data } = event
  selectedInventory.value.push(data)
  viewEditInfoInventoryStore._actionViewEditInfoInventory(data)
}

// Funcion para seleccionar todas las filas
const onRowSelectAll = (event, data) => {
  selectedInventory.value = []
  viewEditInfoInventoryStore._actionClearViewEditInfoInventory()
  throw new Error('Opción no implementada')
}

// Función para deseleccionar una fila
const onRowUnselect = (event) => {
  const { data } = event

  // Remover el registro del array viewEditInfoInventory
  const index = _viewEditInfoInventory.value.findIndex((item) => item.id === data.id)
  if (index !== -1) {
    _viewEditInfoInventory.value.splice(index, 1)
  }

  if (selectedInventory.value.length === 0) {
    _showRightSidebar.value = false
    _editRightSidebar.value = false
    _viewEditInfoInventory.value = []
  }
}

// Función para exportar a excel
const exportarExcel = async () => {
  toast.add({
    severity: 'info',
    summary: 'Exportando a excel',
    detail: 'El archivo se está procesando. La descarga se iniciará automáticamente cuando esté lista.',
    life: 5000,
    group: 'br'
  })

  // Obtener los filtros
  const queryParams = { ...route.query }

  await _InventoryService.value.exportExcelInventory(queryParams).then((url) => {
    window.open(url, '_blank')
  }).catch((err) => {
    console.error(err.message)
    error(err.response.data.message)
  })
}

// Funcion para limpiar los filtros
const resetClear = () => {
  // Limpiar _filtersAdvanced
  _filtersAdvanced.value = [{ nameField: null, condition: null, showName: null, dataType: null, value: null, isAttribute: null }]
  _filtersApply.value = [{ nameField: null, condition: null, showName: null, dataType: null, value: null, isAttribute: null }]
}

// Funcion para retornar el router query de la URL
const getRouteQuery = async () => {
  // Si el objeto no viene vació
  if (Object.keys(route.query).length !== 0) {
    delete route.query.limit
    delete route.query.offset
    delete route.query.order

    // Decodificar los filtros avanzados
    if (route.query.filters) {
      _filtersAdvanced.value = JSON.parse(decodeURIComponent(route.query.filters))
      _filtersApply.value = JSON.parse(decodeURIComponent(route.query.filters))
    }
  } else {
    resetClear()
  }
}

// Función para mostrar los filtros aplicados
const getFiltersApplyV2 = () => {
  let htmlFilters = ''
  if (_filtersApply.value.length > 0 && _filtersApply.value[0].nameField) {
    if (!_filtersApply.value[0].conditions && !_filtersApply.value[0].choiceList) {
      // Agregar choiceList y conditions a los filtros dependiendo del campo, sobre _listFiltersFieldsAll y _listFiltersConditions
      _filtersApply.value.forEach(obj => {
        const field = _listFiltersFieldsAll.value.find((field) => {
          return field.nameField === obj.nameField
        })
        obj.choiceList = field.choiceList
          ? field.choiceList.map(item => {
            return {
              value: item.id ?? item.value,
              label: item.name ? item.name : item.devicename
                ? item.devicename : item.brandname
                  ? item.brandname : item.modelname
                    ? item.modelname : item.ownername
                      ? item.ownername : item.statusname
                        ? item.statusname : item.label
            }
          })
          : []
        obj.conditions = _listFiltersConditions.value.find(item => item.dataType === obj.dataType).conditions
      })
    }
    const result = _filtersApply.value.map(obj => {
      const conditionName = obj.conditions.find(cond => cond.value === obj.condition)?.name
      // Validar si choiceList es un array vació, sino values es igual a obj.value
      const valuesFilters = obj.choiceList && obj.choiceList.length > 0 && obj.value && obj.value.length > 0
        ? obj.choiceList.filter(choice => obj.value.includes(choice.value)).map(choice => choice.label)
        : obj.value

      return {
        showName: obj.showName,
        conditionName,
        valuesFilters: valuesFilters || 'Todos'
      }
    })

    htmlFilters += `
      <div class='flex flex-wrap w-full mt-1'>
        <span class='text-sm text-gray-200 font-bold'>Listado de Filtros aplicados:</span>
      </div>
    `

    // Mostrar los filtros aplicados
    for (let i = 0; i < result.length; i++) {
      htmlFilters += `
        <div class='flex flex-wrap items-center w-full my-2 p-2 bg-gray-600'>
          <span class='text-xs text-gray-200'>${result[i].showName}</span>
          <span class='text-xs ml-2 text-yellow-400'>${result[i].conditionName}</span>
          <span class='text-xs ml-2 text-gray-200 font-bold'>${result[i].valuesFilters}</span>
        </div>
      `
    }
  }

  return htmlFilters
}

// Watch para limpiar los filtros
watch(() => _limpiarListadoInventory.value, async (value) => {
  if (value) {
    resetClear()
    filtersInventoryStore.setLimpiarListadoInventory(false)
    filtersInventoryStore.setBuscarListadoInventory(true)
  }
})

// Watch para filtrar el listado
watch(() => _buscarListadoInventory.value, async (value) => {
  if (value) {
    offset.value = 0
    filtersInventoryStore.setBuscarListadoInventory(false)
    // Enviar los filtros a la URL pero quitando choiceList y conditions
    const filters = JSON.parse(JSON.stringify(_filtersAdvanced.value)) // Create a deep copy
    for (const key in filters) {
      if ('choiceList' in filters[key]) {
        delete filters[key].choiceList
      }
      if ('conditions' in filters[key]) {
        delete filters[key].conditions
      }
    }
    // Si se agrega un nuevo filtro, se actualiza la URL y por defecto debe tomar el limite de 20 registros y la página 1
    _routerPush({ filters: JSON.stringify(filters), limit: 20, offset: 0 })
    filtersUrl.value = JSON.parse(JSON.stringify(_filtersAdvanced.value))
    selectedColumns.value = listColumns.value
  }
})

// Watch para refrescar el listado
watch(() => _refrescarListadoInventory.value, async (value) => {
  if (value) {
    await fetchListInventory()
    filtersInventoryStore.setRefrescarListadoInventory(false)
  }
})

// Watch si cambian los parámetros de la URL
watch(() => route.query, async () => {
  await fetchListInventory()
})

// Watch cuando cambia _filters.value.brands
/* watch(() => _filters.value.brands, async (value) => {
  await filtersInventoryStore._actionFilterModels(value)
  _filters.value.models = []
}) */

// Observa _filtersApply.value y actualiza filtersApplyContent cuando cambie
watch(() => _filtersApply.value, () => {
  filtersApplyContent.value = getFiltersApplyV2()
})

// Funcion para limpiar los filtros
const resetClearFilter = () => {
  console.log('Limpiar Filtros...')
  filtersInventoryStore.setLimpiarListadoInventory(true)
}

// Funcion para recargar la vista
const reload = () => {
  console.log('Recargar...')
  filtersInventoryStore.setRefrescarListadoInventory(true)
  if (selectedInventory.value.length > 0) {
    viewEditInfoInventoryStore._actionViewEditInfoInventory({ id: _viewEditInfoInventory.value[0].id }, true)
  }
}

// Funcion para mostrar el componente según la columna en la tabla
const showComponentColumn = (column) => {
  // Si la columna comienza con 'attributes.', retorna ColumnTemplateAttributes
  if (column.startsWith('attributes.')) {
    return ColumnTemplateAttributes
  }

  // Si la columna existe en el objeto columnComponents, retorna el componente correspondiente
  if (columnComponents[column]) {
    return columnComponents[column]
  }

  // Si la columna no se encuentra en el objeto, retorna el componente por defecto
  return ColumnTemplateDefault
}

// Hook
onMounted(async () => {
  if (route.query.filters) {
    // Parsear los filtros avanzados
    filtersUrl.value = JSON.parse(decodeURIComponent(route.query.filters))
    if (filtersUrl.value.length > 0 && filtersUrl.value[0].nameField) {
      await filtersInventoryStore._actionFilterFields()
      await filtersInventoryStore._actionFilterTypeDevice()
      await filtersInventoryStore._actionFilterBrands()
      await filtersInventoryStore._actionFilterModels()
      await filtersInventoryStore._actionFilterLocations()
      await filtersInventoryStore._actionFilterOwners()
      await filtersInventoryStore._actionFilterStatus()
    }
  }

  viewEditInfoInventoryStore._actionClearViewEditInfoInventory()

  // filtersUrl.value = JSON.parse(JSON.stringify(_filtersAdvanced.value))
  await fetchListInventory()
  await getRouteQuery()

  // Recuperar la configuración de las columnas de localStorage
  const savedColumns = localStorage.getItem('selectedColumns')
  if (savedColumns) {
    selectedColumns.value = JSON.parse(savedColumns)
  } else {
    selectedColumns.value = listColumns.value
  }
})
</script>

<template>
  <Toast position="bottom-right" group="br"/>

  <div class="box intro-x h-full">
    <div class="mt-0">
      <!-- Titulo Card -->
      <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
        <h3 class="mr-auto capitalize text-base font-medium">Listado</h3>
        <div class="flex flex-col sm:flex-row gap-2 sm:gap-0">
          <!-- Botón mas filtros -->
          <Button
            severity="warning"
            text
            size="small"
            style="height: 30px"
            class="mr-2"
            @click="onSidebarAdvancedFilters(null)"
          >
            <div class="flex flex-col">
              <div class="flex flex-wrap justify-center items-center">
                <Icon icon="mdi:plus" class="text-sm" />
                <span class="ml-2 text-xs font-bold">Filtros</span>
              </div>
            </div>
          </Button>
          <!-- Botón ocultar filtros principales -->
          <Button
            :severity="_filtersApply.length > 0 && _filtersApply[0].nameField ? 'success' : 'secondary'"
            text
            size="small"
            style="height: 30px"
            class="mr-2"
            v-tippy="{
              content: filtersApplyContent,
              trigger: 'click'
            }"
          >
            <div class="flex flex-col">
              <div class="flex flex-wrap justify-center items-center">
                <Icon :icon="_filtersApply.length > 0 && _filtersApply[0].nameField ? 'mdi:filter-check' : 'mdi:filter-remove'" class="text-sm" />
                <span class="ml-2 text-xs font-bold">{{ _filtersApply.length > 0 && _filtersApply[0].nameField ? 'Ver filtros' : 'Sin filtros' }}</span>
              </div>
            </div>
          </Button>
          <!-- Botones de acción -->
          <div class="flex flex-wrap h-full gap-2 mr-4">
            <Button
              icon="pi pi-trash"
              severity="danger"
              class="p-button-xs w-full"
              style="height: 30px"
              @click="resetClearFilter"
              :disabled="filtersUrl.length > 0 && !filtersUrl[0].nameField"
              v-tippy="{ content: 'Limpiar Filtros' }"
            />
            <Button
              icon="pi pi-refresh text-sm"
              severity="success"
              class="p-button-xs w-full"
              style="height: 30px"
              @click="reload()"
              v-tippy="{ content: 'Recargar' }"
            />
          </div>
          <!-- Botón exportar a excel -->
          <Button
            icon="pi pi-external-link"
            size="small"
            severity="secondary"
            label="Exportar a excel"
            @click="exportarExcel"
            style="height: 30px"
          />
        </div>
      </div>

      <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 p-4">
        <!-- <pre>{{ JSON.stringify(getterFiltersSolicitudPermisos, null, '\t') }}</pre> -->
        <div class="col-span-1 sm:col-span-12 md:col-span-12">
          <DataTable
            ref="dt"
            :value="listInventory.data"
            v-model:selection="selectedInventory"
            tableStyle="min-width: 50rem"
            class="p-datatable-sm text-xs"
            stripedRows
            dataKey="id"
            scrollable
            scrollHeight="620px"
            @rowSelect="onRowSelect"
            @row-select-all="onRowSelectAll"
            @rowUnselect="onRowUnselect"
            @sort="orderQuery"
            :sortField="orderField"
            :sortOrder="sortOrder"
            :reorderableColumns="true"
            @columnReorder="onColReorder"
            resizableColumns
            columnResizeMode="fit"
            :pt="{
              thead: { style: 'z-index: 3'}
            }"
          >
            <template #empty>
              <div class="flex flex-col items-center justify-center" style="height: 500px">
                <Icon icon="mdi:computer" class="text-gray-200" style="font-size: 15rem;"/>
                <span class="text-gray-400 text-2xl">No se encontraron registros</span>
                <span class="text-gray-300">No hemos encontrado ningún elemento, pruebe con otro filtro u opción</span>
              </div>
            </template>
            <template #loading> Cargando la información, por favor espere... </template>
            <template #header>
              <div class="flex flex-wrap items-center ">
                <span class="text-xs font-bold w-1/3">Seleccionar columnas a mostrar:</span>
                <MultiSelect
                  :modelValue="selectedColumns"
                  :options="listColumns"
                  optionLabel="header"
                  @update:modelValue="onToggle"
                  display="chip"
                  scrollHeight="300px"
                  filter
                  placeholder="Select Columns"
                  class="w-2/3 text-sm"
                  :pt="{
                    root: ({
                      props, state, context
                    }) => ({
                      style: 'height: 30px;'
                    }),
                    label: { style: 'font-size: 0.875rem; padding: 0.2rem 0.5rem' },
                    token: { style: 'font-size: 0.6rem; padding: 0.1rem 0.5rem' },
                    header: { style: 'font-size: 0.7rem; padding: 0.5rem 1rem' },
                    item: { style: 'font-size: 0.7rem; padding: 0.45rem 1rem' },
                    filterInput: { style: 'font-size: 0.7rem; padding: 0.3rem 1rem' }
                  }"
                />
              </div>
            </template>
            <Column selectionMode="multiple" style="width: 2.5rem" />
            <Column
              v-for="column in selectedColumns"
              :key="column.field"
              :field="column.field"
              :header="column.header"
              :sortable="true"
            >
              <template #body="{data}">
                <component
                  :is="showComponentColumn(column.field)"
                  :data="data"
                  :column="column.field"
                />
              </template>
            </Column>
          </DataTable>
          <Paginator
            v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(listInventory.total)"
            :rowsPerPageOptions="[5,10,20,30,100, listInventory.total]"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
            @page="onPage($event)"
            :pt="{
              rowPerPageDropdown: {
                input: {
                  style: 'padding: 5px'
                },
              }
            }"
          >
            <template #start>
              <div class="flex flex-wrap items-center">
                <span class="text-xs font-bold">Total registros:</span>
                <span class="text-xs ml-2 text-green-400">{{ listInventory.total }}</span>
              </div>
            </template>
            <template #end>
              <div class="flex flex-wrap items-center">
                <span class="text-xs font-bold">Registros por página:</span>
                <span class="text-xs ml-2 text-green-400">{{ limit }}</span>
              </div>
            </template>
          </Paginator>
        </div>
      </div>
    </div>
  </div>

  <SidebarAdvancedFilters ref="refSidebarAdvancedFilters" />
</template>

<style scoped>

</style>
