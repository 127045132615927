<script setup>
import ListTableInventarioTi from './table/index.vue'
import RightSidebarShow from './components/rightSidebarShow.vue'
import RightSidebarEdit from './components/rightSidebarEdit.vue'
import { useViewEditInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/viewEditInfoInventory.store'
import { useFiltersInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/filtersInventory.store'
import InventoryService from '@/apps/dailyTech/inventario-ti/services/inventory.service'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import Swal from 'sweetalert2'
import { ref } from 'vue'

const toast = useToast()
const viewEditInfoInventoryStore = useViewEditInfoInventoryStore()
const {
  _showRightSidebar,
  _editRightSidebar,
  _isValidFormEdit,
  _formEditSubmitted,
  _viewEditInfoInventory,
  _messageErrorsEdit,
  _photoEdit
} = storeToRefs(viewEditInfoInventoryStore)
const filtersInventoryStore = useFiltersInventoryStore()
const _InventoryService = ref(new InventoryService())

// Funcion para mostrar el rightSidebar
const editRegister = () => {
  viewEditInfoInventoryStore._setEditRightSidebar(true)
}

// Validar campos generales
const validateFields = () => {
  const validations = {
    brand: 'El campo <span class="text-red-600 font-bold">Marca</span> es requerido',
    model: 'El campo <span class="text-red-600 font-bold">Modelo</span> es requerido',
    location: 'El campo <span class="text-red-600 font-bold">Ubicación</span> es requerido',
    status: 'El campo <span class="text-red-600 font-bold">Estado</span> es requerido',
    owner: 'El campo <span class="text-red-600 font-bold">Dueño</span> es requerido'
  }

  let isFormEditValid = true

  for (const field in validations) {
    if (!_viewEditInfoInventory.value[0][field]) {
      _messageErrorsEdit.value.push(validations[field])
      isFormEditValid = false
    }
  }

  return isFormEditValid
}

// Validar campos de las características
const validateFeatures = () => {
  if (_viewEditInfoInventory.value[0].fieldsAttributes.length !== 0) {
    let isFormEditValid = true

    _viewEditInfoInventory.value[0].fieldsAttributes.forEach((field) => {
      if (field && field.isRequired && !field.value) {
        _messageErrorsEdit.value.push(`El campo <span class="text-red-600 font-bold">${field.showName}</span> es requerido`)
        isFormEditValid = false
      }

      if (field && field.dataType === 'number' && field.value && isNaN(field.value)) {
        _messageErrorsEdit.value.push(`El campo <span class="text-red-600 font-bold">${field.showName}</span> debe ser un número`)
        isFormEditValid = false
      }

      if (field && field.dataType === 'email') {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        if (field.value && !emailPattern.test(field.value)) {
          _messageErrorsEdit.value.push(`El campo <span class="text-red-600 font-bold">${field.showName}</span> debe ser un correo electrónico válido`)
          isFormEditValid = false
        }
      }
    })

    return isFormEditValid
  }

  return true
}

// Funcion para guardar la edición del registro
const saveEditRegister = () => {
  _isValidFormEdit.value = true
  _formEditSubmitted.value = true
  _messageErrorsEdit.value = []

  // Realizar validaciones
  if (!validateFields() || !validateFeatures()) {
    _isValidFormEdit.value = false
    toast.add({ severity: 'error', summary: 'Validación', detail: 'Validación incorrecta, por favor revisar e intentar de nuevo', life: 3000 })
    return
  }

  Swal.fire({
    icon: 'info',
    title: '¿Esta seguro/a?',
    text: '¿Desea editar el registro de inventario?',
    showConfirmButton: true,
    confirmButtonColor: '#24963E',
    confirmButtonText: 'Si, Editar!',
    showCancelButton: true,
    cancelButtonColor: '#d33',
    cancelButtonText: 'No, cancelar!'
  }).then(async (resp) => {
    if (resp.isConfirmed) {
      try {
        const { id, typeDevice, brand, model, location, status, owner, fieldsAttributes } = _viewEditInfoInventory.value[0]
        const formData = new FormData()

        // Obtener solo el nameField y value de fieldsAttributes
        const fieldsAttributesCustom = fieldsAttributes.map(({ nameField, showName, value, dataType, isRequired, isUnique }) => ({ nameField, showName, value, dataType, isRequired, isUnique }))

        formData.append('typeDevice', typeDevice)
        formData.append('brand', brand)
        formData.append('model', model)
        formData.append('location', location)
        formData.append('status', status)
        formData.append('owner', owner)
        formData.append('id', id)
        formData.append('image', _photoEdit.value)
        formData.append('fieldsAttributes', JSON.stringify(fieldsAttributesCustom))

        const response = await _InventoryService.value.editInventory(id, formData)
        if (response.status === 200) {
          filtersInventoryStore.setRefrescarListadoInventory(true)
          viewEditInfoInventoryStore._actionClearViewEditInfoInventory()
          toast.add({ severity: 'success', summary: 'Edición', detail: 'Registro de inventario editado correctamente', life: 3000 })
        }
      } catch (error) {
        console.log('Error al editar el registro de inventario: ', error.response)
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          html: `Hubo un error al editar el registro de inventario <br> <span style="font-size: 13px;">${error.response.data.message ?? ''}</span> <br> <span style="font-size: 13px;">${error.response.data.details ?? ''}</span>`
        })
      }
    }
  })
}
</script>

<template>
  <Toast />
  <Toolbar
    class="box my-4 py-2 px-4 flex flex-col sm:flex-row flex-wrap gap-2"
    :pt="{
      end: { class: 'w-full sm:w-auto'}
    }"
  >
    <template #start>
      <h2 class="text-lg font-bold truncate mr-5">Inventario TI</h2>
    </template>
    <template #end>
      <div class="flex flex-col sm:flex-row flex-wrap gap-2">
        <router-link
          :to="{name: 'daily-tech.inventario-ti.configuracion'}"
        >
          <Button
            label="Configuración"
            icon="pi pi-cog"
            class="p-button-xs"
            severity="primary"
          />
        </router-link>
        <router-link
          :to="{name: 'daily-tech.inventario-ti.carga-masiva'}"
        >
          <Button
            label="Carga Masiva Inventario TI"
            icon="pi pi-file-excel"
            class="p-button-xs"
            severity="warning"
          />
        </router-link>
        <router-link
          :to="{name: 'daily-tech.inventario-ti.crear'}"
        >
          <Button
            label="Agregar Inventario TI"
            icon="pi pi-plus"
            class="p-button-xs"
            severity="success"
          />
        </router-link>
      </div>
    </template>
  </Toolbar>

  <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 gap-x-4">
    <div :class="{'col-span-12 sm:col-span-12': !_showRightSidebar, 'col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-9 mb-4 sm:mb-4 lg:mb-4 xl:mb-0': _showRightSidebar || _editRightSidebar}">
      <ListTableInventarioTi />
    </div>
    <div
      v-if="_showRightSidebar"
      :class="{'col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-3': _showRightSidebar, 'hidden': !_showRightSidebar}"
    >
      <RightSidebarShow />
    </div>
    <div
      v-if="_editRightSidebar"
      :class="{'col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-3': _editRightSidebar, 'hidden': !_editRightSidebar}"
    >
      <RightSidebarEdit />
    </div>
  </div>

  <Button
    v-if="!_editRightSidebar && _showRightSidebar"
    label="Abrir Edición"
    icon="pi pi-pencil"
    severity="warning"
    size="small"
    class="p-button-raised floating-button p-button-rounded"
    @click="editRegister"
  />
  <Button
    v-if="_editRightSidebar && !_showRightSidebar"
    label="Guardar Edición"
    icon="pi pi-send"
    severity="success"
    size="small"
    class="p-button-raised floating-button p-button-rounded"
    @click="saveEditRegister"
  />

</template>

<style scoped>
.floating-button {
  position: fixed !important;
  bottom: 30px;
  right: 40px;
  z-index: 1050;
}
</style>
