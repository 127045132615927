import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_INVENTARIO_TI

export default class DevicesService {
  // Obtener los dispositivos
  getDevices (params) {
    return http.get(`${baseUrl}/device/listar-dispositivos`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  // Crear un dispositivo
  createDevice (body) {
    return http.post(`${baseUrl}/device/crear-dispositivo`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Editar un dispositivo
  editDevice (id, body) {
    return http.put(`${baseUrl}/device/editar-dispositivo/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Eliminar un dispositivo
  deleteDevice (id) {
    return http.delete(`${baseUrl}/device/eliminar-dispositivo/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
