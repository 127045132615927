<script setup>
import { defineExpose, onMounted, ref } from 'vue'
import { useFiltersInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/filtersInventory.store'
import { useViewEditInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/viewEditInfoInventory.store'
import { storeToRefs } from 'pinia'
import { Icon } from '@iconify/vue'

const filtersInventoryStore = useFiltersInventoryStore()
const viewEditInfoInventoryStore = useViewEditInfoInventoryStore()
const {
  _filtersApply,
  _filtersAdvanced,
  _listTypeDevices,
  _listBrands,
  _listModels,
  _listDepartamentos,
  _listMunicipios,
  _listLocations,
  _listOwners,
  _listStatus,
  _listFiltersFieldsGeneral,
  _listFiltersFieldsAttributes,
  _listFiltersConditions,
  _listFiltersFieldsAll
} = storeToRefs(filtersInventoryStore)
const visible = ref(false)
const sidebarPosition = ref(window.innerWidth > 1024 ? 'right' : 'full')
const sidebarClass = ref(window.innerWidth > 1024 ? 'p-sidebar-md' : '')
const sidebarStyle = ref(window.innerWidth > 1024 ? 'width: 50vw' : '')

// Abrir el sidebar
const openSidebarAdvancedFilters = async (data) => {
  visible.value = true
  viewEditInfoInventoryStore._actionClearViewEditInfoInventory()
  // Setear lo que hay en _filtersApply en _filtersAdvanced
  if (_filtersApply.value.length > 0) {
    _filtersAdvanced.value = JSON.parse(JSON.stringify(_filtersApply.value))
  } else {
    _filtersAdvanced.value = [{ nameField: null, condition: null, showName: null, dataType: null, value: null, isAttribute: null }]
  }
}

// Evento o funcion para limpiar el store cuando se cierra el sidebar
const onCloseSidebarAdvancedFilters = () => {
  visible.value = false
}

// Funcion para unir la lista de filtros avanzados con los filtros generales
const mergeFilters = () => {
  _listFiltersFieldsAll.value = [..._listFiltersFieldsGeneral.value, ..._listFiltersFieldsAttributes.value]

  // Recorrer _filtersAdvanced para asignar el choiceList dependiendo si es typeDevice, brand, model, location, owner o status
  _listFiltersFieldsAll.value.forEach((field) => {
    if (field.nameField === 'typeDevice') {
      field.choiceList = _listTypeDevices.value
    } else if (field.nameField === 'brand') {
      field.choiceList = _listBrands.value
    } else if (field.nameField === 'model') {
      field.choiceList = _listModels.value
    } else if (field.nameField === 'locationDepto') {
      field.choiceList = _listDepartamentos.value
    } else if (field.nameField === 'locationCity') {
      field.choiceList = _listMunicipios.value
    } else if (field.nameField === 'location') {
      field.choiceList = _listLocations.value
    } else if (field.nameField === 'owner') {
      field.choiceList = _listOwners.value
    } else if (field.nameField === 'status') {
      field.choiceList = _listStatus.value
    }
  })
}

// Agregar fila para aplicar otro filtro
const addRow = () => {
  _filtersAdvanced.value.push({ nameField: null, condition: null, showName: null, dataType: null, value: null, isAttribute: null })
}

// Eliminar fila de filtro
const removeRow = (index) => {
  // Si solo hay una fila, no se puede eliminar
  if (_filtersAdvanced.value.length === 1) {
    return
  }
  _filtersAdvanced.value.splice(index, 1)
}

// Cambiar el campo seleccionado
const onChangeField = (field) => {
  const { nameField } = field

  field.value = null
  field.condition = null

  // Obtener el showName, dataType y isAttribute del campo seleccionado
  const { showName, dataType, isAttribute } = _listFiltersFieldsAll.value.find(item => item.nameField === nameField)
  field.showName = showName
  field.dataType = dataType
  field.isAttribute = isAttribute
  // Obtener el listado de condiciones dependiendo del tipo de campo
  field.conditions = _listFiltersConditions.value.find(item => item.dataType === field.dataType).conditions
  // Si es de dataType select, checkbox, radio o multiselect, asignar el choiceList
  if (['select', 'checkbox', 'radio', 'multiselect'].includes(field.dataType)) {
    field.choiceList = _listFiltersFieldsAll.value.find(item => item.nameField === nameField).choiceList

    const fieldMap = {
      typeDevice: { valueKey: 'id', labelKey: 'devicename' },
      brand: { valueKey: 'id', labelKey: 'brandname' },
      model: { valueKey: 'id', labelKey: 'modelname' },
      location: { valueKey: 'id', labelKey: 'name', extraKeys: ['depto', 'ciudad'] },
      owner: { valueKey: 'id', labelKey: 'ownername' },
      status: { valueKey: 'id', labelKey: 'statusname' }
    }

    if (fieldMap[nameField]) {
      const { valueKey, labelKey, extraKeys } = fieldMap[nameField]
      field.choiceList = field.choiceList.map(item => {
        const newItem = {
          value: item[valueKey] ?? item.value,
          label: item[labelKey]
        }
        if (extraKeys) {
          extraKeys.forEach(key => {
            newItem[key] = item[key]
          })
        }
        return newItem
      })
    } else {
      field.choiceList = field.choiceList.map(item => ({ value: item.id ?? item.value, label: item.label }))
    }
  } else {
    field.choiceList = []
  }

  console.log('ChoiceList --> ', field.choiceList)
  // Validar si es dataType number y asignar el numberFormat
  if (field.dataType === 'number') {
    field.numberFormat = _listFiltersFieldsAll.value.find(item => item.nameField === nameField).numberFormat
  } else {
    field.numberFormat = null
  }
}

// Funcion si cambia la condicion
const onChangeCondition = (field) => {
  field.value = null
}

// Aplicar filtros avanzados
const onApplyFiltersAdvanced = () => {
  _filtersApply.value = [..._filtersAdvanced.value]
  visible.value = false

  filtersInventoryStore.setBuscarListadoInventory(true)
}

// Limpiar los filtros avanzados
const onClearFiltersAdvanced = () => {
  filtersInventoryStore.actionClearFilters()
}

window.addEventListener('resize', evt => {
  sidebarPosition.value = window.innerWidth > 1024 ? 'right' : 'full'
  sidebarClass.value = window.innerWidth > 1024 ? 'p-sidebar-md' : ''
  sidebarStyle.value = window.innerWidth > 1024 ? 'width: 50vw' : ''
})

// Se expone la funcion para abrir el sidebar, para que pueda ser llamado desde el componente padre
defineExpose({
  openSidebarAdvancedFilters
})

// Hook
onMounted(async () => {
  await filtersInventoryStore._actionFilterFields()
  await filtersInventoryStore._actionFilterTypeDevice()
  await filtersInventoryStore._actionFilterBrands()
  await filtersInventoryStore._actionFilterModels()
  await filtersInventoryStore._actionFilterLocations()
  await filtersInventoryStore._actionFilterOwners()
  await filtersInventoryStore._actionFilterStatus()
  await mergeFilters()
})
</script>

<template>
  <Sidebar
    v-model:visible="visible"
    :position="sidebarPosition"
    :baseZIndex="1000"
    :class="sidebarClass"
    :style="sidebarStyle"
    @hide="onCloseSidebarAdvancedFilters"
    :dismissable="false"
    :pt="{
        header: {
          style: 'display: flex; align-items: center; justify-content: space-between; width: 100%'
        }
      }"
  >
    <template #header>
      <div class="flex items-center justify-between gap-2 w-full">
        <!-- Titulo -->
        <Icon icon="carbon:filter" class="text-xl text-gray-500" />
        <h3 class="text-lg font-semibold">Filtros Avanzados</h3>
      </div>

    </template>

    <div class="flex flex-col">
      <p class="text-sm text-gray-500">Realice los filtros avanzados para la búsqueda de los registros en el inventario:</p>
    </div>

    <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 gap-4">
      <div class="col-span-1 sm:col-span-12 md:col-span-12">
        <!-- Contenedor de los filtros AND -->
        <div class="bg-gray-100 rounded-md p-4 mt-4">
          <!-- Filtros AND -->
          <div
            class="flex flex-wrap items-center bg-white p-4 rounded-md"
            :class="{ 'mb-2': index !== _filtersAdvanced.length - 1 }"
            v-for="(field, index) in _filtersAdvanced"
            :key="index"
          >
            <!-- Borrar la fila -->
            <div class="w-full sm:w-auto sm:mr-2">
              <label class="block text-xs mb-1">&nbsp;</label>
              <Button
                icon="pi pi-trash"
                size="small"
                class="p-button-danger p-button-sm"
                @click="removeRow(index)"
                v-tippy="{ content: 'Eliminar fila' }"
                :pt="{
                  root: { style: 'height: 32px' }
                }"
              />
            </div>
            <!-- Dropdown de los campos -->
            <div class="flex-grow sm:w-4/12 sm:mr-2">
              <label for="field" class="block text-xs font-medium text-gray-700 mb-1">Campos:</label>
              <Dropdown
                v-model="field.nameField"
                :options="_listFiltersFieldsAll"
                @change="onChangeField(field)"
                optionLabel="showName"
                optionValue="nameField"
                placeholder="Selecciona un campo"
                class="w-full text-sm"
                :filter="true"
                filterPlaceholder="Buscar por nombre"
                scrollHeight="300px"
                :pt="{
                  root: { style: 'height: 32px' },
                  input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                  panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                  item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                }"
              />
            </div>
            <!-- Dropdown de condiciones -->
            <div class="flex-grow sm:w-2/12 sm:mr-2">
              <label for="condition" class="block text-xs font-medium text-gray-700 mb-1">Condición:</label>
              <Dropdown
                v-if="field.conditions"
                v-model="field.condition"
                :options="field.conditions"
                @change="onChangeCondition(field)"
                optionLabel="name"
                optionValue="value"
                placeholder="Selecciona una condición"
                class="w-full text-sm"
                :pt="{
                  root: { style: 'height: 32px' },
                  input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                  panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                  item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                }"
              />
              <!-- Mantener el espacio -->
              <InputText
                v-else
                disabled
                v-model="field.condition"
                size="small"
                class="w-full"
                :pt="{
                  root: { style: 'height: 2rem' },
                  input: { style: 'font-size: 0.875rem' },
                  panel: { style: 'font-size: 0.875rem' }
                }"
              />
            </div>
            <!-- Input de valor -->
            <div class="flex-grow sm:w-4/12 sm:mr-2">
              <label for="value" class="block text-xs font-medium text-gray-700 mb-1">Valor:</label>
              <InputText
                v-if="field.dataType === 'text' && field.condition"
                type="text"
                v-model="field.value"
                size="small"
                class="w-full"
                :pt="{
                  root: { style: 'height: 2rem' },
                  input: { style: 'font-size: 0.875rem' },
                  panel: { style: 'font-size: 0.875rem' }
                }"
              />
              <InputText
                v-else-if="field.dataType === 'date' && field.condition"
                type="date"
                v-model="field.value"
                size="small"
                class="w-full"
                :pt="{
                  root: { style: 'height: 2rem' },
                  input: { style: 'font-size: 0.875rem' },
                  panel: { style: 'font-size: 0.875rem' }
                }"
              />
              <InputNumber
                v-else-if="field.dataType === 'number' && field.condition"
                v-model="field.value"
                class="w-full"
                :mode="field.numberFormat === 'currency' ? 'currency' : 'decimal'"
                :currency="`COP`"
                :locale="`es-CO`"
                :useGrouping="field.numberFormat !== 'integer'"
                :maxFractionDigits="field.numberFormat === 'decimal' ? 1 : 0"
                :suffix="field.numberFormat === 'percent' ? '%' : ''"
                :pt="{
                  root:{ style: 'height: 2rem' },
                  input: { style: 'width:100%; font-size: 0.875rem;' }
                }"
              />
              <MultiSelect
                v-else-if="[ 'select', 'checkbox', 'radio', 'multiselect' ].includes(field.dataType) && field.condition && !['notEquals', 'equals'].includes(field.condition)"
                v-model="field.value"
                :options="field.choiceList"
                optionValue="value"
                optionLabel="label"
                :placeholder="'Todos '+field.showName"
                scrollHeight="300px"
                filter
                :filterFields="field.nameField === 'location' ? ['label', 'depto', 'ciudad'] : ['label']"
                class="w-full text-sm"
                display="chip"
                :pt="{
                  root: ({
                    props, state, context
                  }) => ({
                    style: 'height: 2rem'
                  }),
                  label: { style: 'font-size: 0.875rem; padding: 0.2rem 0.5rem' },
                  token: { style: 'font-size: 0.6rem; padding: 0.1rem 0.5rem' },
                  header: { style: 'font-size: 0.7rem; padding: 0.5rem 1rem' },
                  item: { style: 'font-size: 0.7rem; padding: 0.45rem 1rem' },
                  filterInput: { style: 'font-size: 0.7rem; padding: 0.3rem 1rem' }
                }"
              >
                <template #option="{ option }">
                  <div class="flex flex-col" v-if="field.nameField === 'location'">
                    <div class="flex flex-wrap justify-between">
                      <span class="font-bold" style="font-size: 0.7rem">{{ option.label.toUpperCase() }} <span class="text-xxs font-normal">({{ option.depto }}-{{ option.ciudad }})</span></span>
                    </div>
                  </div>
                  <div class="flex flex-col" v-else>
                    <span class="font-bold" style="font-size: 0.7rem">{{ option.label }}</span>
                  </div>
                </template>
              </MultiSelect>
              <Dropdown
                v-else-if="[ 'select', 'checkbox', 'radio', 'multiselect' ].includes(field.dataType) && field.condition && ['notEquals', 'equals'].includes(field.condition)"
                v-model="field.value"
                :options="field.choiceList"
                optionValue="value"
                optionLabel="label"
                :placeholder="'Todos '+field.showName"
                filter
                :filterFields="field.nameField === 'location' ? ['label', 'depto', 'ciudad'] : ['label']"
                scrollHeight="300px"
                filterPlaceholder="Buscar por nombre"
                autoFilterFocus
                class="w-full"
                :pt="{
                  root: { style: 'height: 2rem' },
                  input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                  panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                  item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                }"
              >
                <template #option="{ option }">
                  <div class="flex flex-col" v-if="field.nameField === 'location'">
                    <div class="flex flex-wrap justify-between">
                      <span class="font-bold" style="font-size: 0.7rem">{{ option.label.toUpperCase() }} <span class="text-xxs font-normal">({{ option.depto }}-{{ option.ciudad }})</span></span>
                    </div>
                  </div>
                  <div class="flex flex-col" v-else>
                    <span class="font-bold" style="font-size: 0.7rem">{{ option.label }}</span>
                  </div>
                </template>
              </Dropdown>
              <!-- Mantener el espacio -->
              <InputText
                v-else
                disabled
                v-model="field.value"
                size="small"
                class="w-full"
                :pt="{
                  root: { style: 'height: 2rem' },
                  input: { style: 'font-size: 0.875rem' },
                  panel: { style: 'font-size: 0.875rem' }
                }"
              />
            </div>
            <!-- Colocar la palabra AND o el botón para agregar otra fila -->
            <div class="w-full sm:w-auto sm:ml-2">
              <label class="block text-xs mb-1">&nbsp;</label>
              <Button
                v-if="index !== _filtersAdvanced.length - 1"
                text
                label="AND"
                size="small"
                severity="secondary"
                :pt="{
                  root: { style: 'width: 32px; height: 32px; font-size: 10px; padding: 0' }
                }"
              />
              <Button
                v-else
                text
                icon="pi pi-plus"
                size="small"
                class="p-button-success p-button-sm"
                @click="addRow"
                v-tippy="{ content: 'Agregar fila' }"
                :pt="{
                  root: { style: 'width: 32px; height: 32px' }
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

<!--    <pre style="font-size: 11px; line-height: 1"><code>{{ JSON.stringify(_filtersAdvanced, null, '\t') }}</code></pre>-->

    <!-- Contenedor de botones fijos al final -->
    <div class="sidebar-footer">
      <div class="flex justify-end gap-4">
        <!-- Botón para limpiar los filtros -->
        <Button
          label="Limpiar Filtros"
          size="small"
          severity="secondary"
          icon="pi pi-trash"
          @click="onClearFiltersAdvanced"
          v-tippy="{ content: 'Limpiar Filtros' }"
        />
        <!-- Botón para aplicar los filtros -->
        <Button
          label="Aplicar Filtros"
          size="small"
          severity="success"
          icon="pi pi-save"
          @click="onApplyFiltersAdvanced"
          v-tippy="{ content: 'Aplicar Filtros' }"
        />
      </div>
    </div>
  </Sidebar>
</template>

<style scoped>
.sidebar-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 16px;
  border-top: 1px solid #e5e7eb;
}
</style>
