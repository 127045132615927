<script setup>
import { useViewEditInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/viewEditInfoInventory.store'
import { storeToRefs } from 'pinia'
import { useStore } from '@/store'
import { onMounted, reactive, ref } from 'vue'
import Icon from '@/components/Icon/index.vue'
import { useToast } from 'primevue/usetoast'
import BrandService from '@/apps/dailyTech/inventario-ti/services/brands.service'
import ModelsService from '@/apps/dailyTech/inventario-ti/services/models.service'
import InventoryService from '@/apps/dailyTech/inventario-ti/services/inventory.service'
import UploadFilePond from '@/apps/dailyTech/inventario-ti/views/components/uploadFilePond/index.vue'
import UploadFilePondImage from '@/apps/dailyTech/inventario-ti/views/list/components/filePondImage/index.vue'

const store = useStore()
const toast = useToast()
const _BrandService = ref(new BrandService())
const _ModelsService = ref(new ModelsService())
const _InventoryService = ref(new InventoryService())
const viewEditInfoInventoryStore = useViewEditInfoInventoryStore()
const {
  _showRightSidebar,
  _editRightSidebar,
  _viewEditInfoInventory,
  _listBrands,
  _listModels,
  _listLocations,
  _listOwners,
  _listStatus,
  _isValidFormEdit,
  _formEditSubmitted,
  _messageErrorsEdit,
  _photoEdit
} = storeToRefs(viewEditInfoInventoryStore)
const nameDevice = ref('')
const formSubmitted = ref(false)
const opBrand = ref()
const objNewBrand = reactive({
  brandname: null,
  status: true
})
const brandSelected = reactive({
  id: null,
  name: null
})
const opModel = ref()
const objNewModel = reactive({
  modelname: null,
  idbrand: null,
  image: null,
  status: true
})
const modelSelected = reactive({
  modelname: null,
  idbrand: null,
  image: null,
  status: true
})
const existImgModel = ref(false)
const photoModel = ref(null)
const photoInventory = ref(_viewEditInfoInventory.value[0].photo)
const pondFile = ref(null)
const existImgInventory = ref(false)

// Cerrar el sidebar de edición
const hideEditSidebar = () => {
  _showRightSidebar.value = true
  _editRightSidebar.value = false
}

// Abrir OverlayPanel para añadir nueva marca
const toggleNewBrand = (event) => {
  opBrand.value.toggle(event)
}

// Guardar nueva marca
const createBrand = async () => {
  if (!objNewBrand.brandname) return

  await _BrandService.value.createBrand(objNewBrand)
    .then(response => {
      const { data } = response
      if (!data.success) {
        return
      }

      _listBrands.value.push(data.info) // Agregar la marca creada
      _listBrands.value.sort((a, b) => a.brandname.localeCompare(b.brandname)) // Ordenar alfabéticamente
      _viewEditInfoInventory.value[0].brand = data.info.id // Seleccionar la marca creada
      _viewEditInfoInventory.value[0].model = null

      clearValues() // Limpiar valores de objNewBrand y objNewModel

      opBrand.value.hide()
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Marca creada exitosamente', life: 3000 })
    })
    .catch(error => {
      toast.add({ severity: 'error', summary: 'Falló', detail: 'Error al crear la marca', life: 3000 })
      console.log('Error al crear la marca: ', error)
    })
}

// Limpiar valores de objNewBrand y objNewModel
const clearValues = () => {
  objNewBrand.brandname = null
  objNewModel.modelname = null
  objNewModel.idbrand = null
  objNewModel.image = null
}

// Cuando cambia la marca actualiza el modelo
const onChangeBrand = async (e, init = false) => {
  if (!e.value) {
    return
  }

  await viewEditInfoInventoryStore._actionListModels(e.value).then(() => {
    if (_listModels.value.length > 0 && !_viewEditInfoInventory.value[0].model) {
      _viewEditInfoInventory.value[0].model = _listModels.value[0].id
    }
  })
  brandSelected.id = e.value
  brandSelected.name = _listBrands.value.find(brand => brand.id === e.value).brandname

  if (!init) {
    // Limpiar valores de modelSelected a null con un for
    for (const key in modelSelected) {
      modelSelected[key] = null
    }

    // Limpiar registerInventory.model
    _viewEditInfoInventory.value[0].model = null
  }
}

// Guardar nuevo modelo
const createModel = async () => {
  if (!objNewModel.modelname) return

  objNewModel.idbrand = _viewEditInfoInventory.value[0].brand

  const formData = new FormData()
  formData.append('modelname', objNewModel.modelname)
  formData.append('idbrand', objNewModel.idbrand)
  formData.append('status', objNewModel.status)
  formData.append('image', objNewModel.image)

  await _ModelsService.value.createModel(formData)
    .then(response => {
      const { data } = response
      if (!data.success) {
        return
      }

      _listModels.value.push(data.info) // Agregar el modelo creado
      _listModels.value.sort((a, b) => a.modelname.localeCompare(b.modelname)) // Ordenar alfabéticamente
      _viewEditInfoInventory.value[0].model = data.info.id // Seleccionar el modelo creado
      clearValues() // Limpiar valores de objNewBrand y objNewModel
      opModel.value.hide()
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Modelo creado exitosamente', life: 3000 })
    })
    .catch(error => {
      toast.add({ severity: 'error', summary: 'Falló', detail: 'Error al crear el modelo', life: 3000 })
      console.log('Error al crear el modelo: ', error)
    })
}

// Abrir OverlayPanel para añadir nuevo modelo
const toggleNewModel = (event) => {
  opModel.value.toggle(event)
}

// Inicializar filepond usado para la imagen del modelo nuevo
const onInitFilePondNew = () => {
  console.log('Inicializando filepond new...')
  existImgModel.value = false
}
// Capturar archivo del modelo nuevo
const onCaptureFileNew = (files) => {
  console.log('Capturando archivo new: ', files)
  objNewModel.image = files
  existImgModel.value = false
  if (!modelSelected.image) {
    photoModel.value = files
  } else {
    photoModel.value = null
  }
}
// Remover archivo del modelo nuevo
const onRemoveFileNew = () => {
  console.log('Removiendo archivo new...')
  objNewModel.image = null
  if (modelSelected.image) {
    existImgModel.value = true
  }

  photoModel.value = null
}

// Obtener la imagen del registro inventario
const getImageInventory = (image) => {
  // Detectar si la imagen es object
  if (typeof image === 'object') {
    return URL.createObjectURL(image)
  }

  const token = store.getters['auth/getToken']
  const obj = { image, token }
  return _InventoryService.value.getImageInventory(obj)
}

// Inicializar filepond usado para la imagen del modelo nuevo
const onInitFilePondImage = () => {
  console.log('Inicializando filepond image...')
}

// Capturar archivo de la imagen del registro
const onCaptureFileImage = (files) => {
  if (files) {
    _photoEdit.value = files
    photoInventory.value = files
    existImgInventory.value = false
  } else {
    console.error('No se seleccionó ningún archivo')
  }
}

/* Limpiando el attach cuando se remueve o reemplaza el archivo */
const onRemoveFileImage = (files) => {
  _photoEdit.value = _viewEditInfoInventory.value[0].photo
  photoInventory.value = _viewEditInfoInventory.value[0].photo
  existImgInventory.value = true
}

// Hook
onMounted(async () => {
  nameDevice.value = _viewEditInfoInventory.value[0].attributes.nombre
  await viewEditInfoInventoryStore._actionListBrands()
  await onChangeBrand({ value: _viewEditInfoInventory.value[0].brand }, true)
  await viewEditInfoInventoryStore._actionListLocations()
  await viewEditInfoInventoryStore._actionListOwners()
  await viewEditInfoInventoryStore._actionListStatus()

  _photoEdit.value = [_viewEditInfoInventory.value[0].photo]

  if (photoInventory.value) {
    existImgInventory.value = true
  }
})
</script>

<template>
  <Toast />
  <div class="box h-full" :class="{'hidden': !_editRightSidebar}">
    <div class="mt-0 flex flex-col" style="height: 892px;padding-bottom: 16px;">
      <!-- Titulo del sidebar -->
      <div style="height: 63px;" class="flex flex-row p-4 items-center border-b border-gray-200 dark:border-dark-5">
        <h3 class="mr-auto text-base font-medium">Editar registro: <span class="text-yellow-800 font-bold">{{ nameDevice ?? 'Sin nombre' }}</span></h3>
        <!-- Botón para cerrar el sidebar de edición -->
        <Button
          :severity="'secondary'"
          text
          size="small"
          style="height: 30px"
          class="mr-2"
          icon="pi pi-times"
          @click="hideEditSidebar"
          v-tippy="{ content: 'Cerrar Edición' }"
        />
      </div>

      <!-- Contenido -->
      <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 gap-4 p-4 overflow-y-auto">

        <!-- Mensajes de error -->
        <div class="col-span-12 sm:col-span-12" v-if="!_isValidFormEdit && _formEditSubmitted">
          <Badge
            :value="`Visualizar mensajes de error de validación`"
            style="height: 1.2rem; font-size: 0.7rem; line-height: 1.2rem;"
            class="bg-red-100 text-red-900 capitalize cursor-pointer font-bold"
            v-tippy="{ content: _messageErrorsEdit.join('<br>') }"
          />
        </div>

        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col items-center">
            <span class="text-gray-600 text-base font-bold">{{ _viewEditInfoInventory[0].typeDeviceName }}</span>
            <div v-if="existImgInventory" class="flex justify-center items-center bg-gray-200 rounded-md" style="width: 150px; height: 150px">
              <Image
                v-if="photoInventory"
                :src="getImageInventory(photoInventory)"
                alt="Image"
                preview
                :pt="{
                  root: { class: 'flex justify-center items-center w-full h-full bg-gray-200 rounded-md' },
                  image: { style: 'width: 150px; height: 150px; object-fit: contain;' }
                }"
              />
              <Icon v-else :icon="_viewEditInfoInventory[0].typeDeviceIcon ? _viewEditInfoInventory[0].typeDeviceIcon : 'material-symbols:devices-off-outline-rounded'" style="opacity: 0.4; font-size: 8rem" />
            </div>
          </div>
          <!-- Mostrar la imagen del registro -->
          <UploadFilePondImage
            class="w-full"
            ref="pondFile"
            @initFile="onInitFilePondImage"
            @captureFile="onCaptureFileImage"
            @removeFile="onRemoveFileImage"
          />
        </div>

        <!-- Información del registro -->
        <div class="col-span-12 sm:col-span-12">
          <Divider
            align="left"
            type="dashed"
            :pt="{
              root: { style: 'margin: 7px 0 7px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-sm font-semibold cursor-pointer text-blue-900">Información del registro</span>
          </Divider>
        </div>

        <!-- Marca -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-wrap justify-between">
            <label for="brand" class="block text-xs font-medium text-gray-700 mb-2">
              Marca:
              <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <!-- Añadir nueva marca -->
            <div
              class="flex flex-wrap items-start cursor-pointer text-xs text-blue-600 hover:underline"
              @click="toggleNewBrand($event)"
            >
              <Icon icon="mdi:plus-circle" class="text-base mr-1" />
              <span>Añadir nueva marca</span>
            </div>
            <OverlayPanel
              ref="opBrand"
              :pt="{
                root: {
                  style: 'width: 300px; margin-top: 0'
                },
                content: {
                  style: 'padding: 1rem'
                }
              }"
            >
              <div class="flex flex-col">
                <label for="nameBrand" class="block text-xs font-medium text-gray-700 mb-2">
                  Nombre:
                  <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                </label>
                <InputText
                  id="nameBrand"
                  placeholder="Ingresa el nombre de la marca"
                  v-model="objNewBrand.brandname"
                />
                <!-- Mensaje de error -->
                <div class="text-xxs text-red-500" v-if="!objNewBrand.brandname">El campo es requerido.</div>
                <Button
                  label="Guardar"
                  icon="pi pi-save"
                  severity="success"
                  size="small"
                  class="mt-2"
                  @click="createBrand"
                />
              </div>
            </OverlayPanel>
          </div>
          <Dropdown
            id="brand"
            v-model="_viewEditInfoInventory[0].brand"
            :options="_listBrands"
            @change="onChangeBrand"
            optionLabel="brandname"
            optionValue="id"
            placeholder="Seleccione una opción"
            :filter="true"
            scrollHeight="300px"
            filterPlaceholder="Buscar por nombre"
            autoFilterFocus
            class="w-full"
            :class="{ 'border-red-600': formSubmitted && !_viewEditInfoInventory[0].brand }"
            :pt="{
              root: { style: 'height: 32px' },
              input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
              panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
              item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
            }"
          />
        </div>

        <!-- Modelo -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-wrap justify-between">
            <label for="model" class="block text-xs font-medium text-gray-700 mb-2">
              Modelo:
              <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <!-- Añadir nuevo modelo -->
            <div
              v-if="_viewEditInfoInventory[0].brand"
              class="flex flex-wrap items-start cursor-pointer text-xs text-blue-600 hover:underline"
              @click="toggleNewModel($event)"
            >
              <Icon icon="mdi:plus-circle" class="text-base mr-1" />
              <span>Añadir nuevo modelo</span>
            </div>
            <OverlayPanel
              ref="opModel"
              :pt="{
                root: {
                  style: 'width: 300px; margin-top: 0'
                },
                content: {
                  style: 'padding: 1rem'
                }
              }"
            >
              <div class="flex flex-col">
                <div class="flex flex-col">
                  <label for="nameModel" class="block text-xs font-medium text-gray-700 mb-2">
                    Nombre:
                    <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                  </label>
                  <InputText
                    id="nameModel"
                    placeholder="Ingresa el nombre del modelo"
                    v-model="objNewModel.modelname"
                  />
                  <!-- Mensaje de error -->
                  <div class="text-xxs text-red-500" v-if="!objNewModel.modelname">El campo es requerido.</div>
                </div>
                <div class="flex flex-col mt-2">
                  <label for="image" class="block text-xs font-medium text-gray-700 mb-2">Imagen:</label>
                  <UploadFilePond
                    :typeFilePond="'newModel'"
                    @initFilePondNew="onInitFilePondNew"
                    @captureFileNew="onCaptureFileNew"
                    @removeFileNew="onRemoveFileNew"
                  />
                </div>
                <!-- Mostrar la marca seleccionada -->
                <div class="text-xs font-medium text-gray-700 mb-2">Marca: {{ brandSelected.name }}</div>
                <Button
                  label="Guardar"
                  icon="pi pi-save"
                  severity="success"
                  size="small"
                  class="mt-2"
                  @click="createModel"
                />
              </div>
            </OverlayPanel>
          </div>
          <Dropdown
            id="model"
            v-model="_viewEditInfoInventory[0].model"
            :options="_listModels"
            optionLabel="modelname"
            optionValue="id"
            placeholder="Seleccione una opción"
            :disabled="!_viewEditInfoInventory[0].brand"
            :filter="true"
            scrollHeight="300px"
            filterPlaceholder="Buscar por nombre"
            autoFilterFocus
            :emptyMessage="`${_viewEditInfoInventory[0].brand ? 'No hay modelos disponibles para la marca seleccionada. Por favor, añade un nuevo modelo.' : 'Seleccione una marca para ver los modelos disponibles.'}`"
            class="w-full"
            :class="{ 'border-red-600': formSubmitted && !_viewEditInfoInventory[0].model }"
            :pt="{
              root: { style: 'height: 32px' },
              input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
              panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
              item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
            }"
          />
        </div>

        <!-- Ubicacion -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-wrap justify-between">
            <label for="location" class="block text-xs font-medium text-gray-700 mb-2">
              Ubicación:
              <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Dropdown
              id="location"
              v-model="_viewEditInfoInventory[0].location"
              :options="_listLocations"
              optionLabel="name"
              optionValue="id"
              placeholder="Seleccione una opción"
              :filter="true"
              scrollHeight="300px"
              filterPlaceholder="Buscar por nombre"
              autoFilterFocus
              class="w-full"
              :class="{ 'border-red-600': formSubmitted && !_viewEditInfoInventory[0].location }"
              :pt="{
                root: { style: 'height: 32px' },
                input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
              }"
            >
              <template #option="{ option }">
                <div class="flex flex-col">
                  <div class="flex flex-wrap justify-between">
                    <span class="font-bold" style="font-size: 0.7rem">{{ option.name.toUpperCase() }}</span>
                    <span class="font-bold text-gray-700" style="font-size: 0.7rem">{{ option.tipo_sede }}</span>
                  </div>
                  <div class="flex flex-wrap justify-between">
                    <span style="font-size: 0.7rem">{{ option.address }}</span>
                    <Badge
                      :value="`${option.estado}`"
                      style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                      class="whitespace-nowrap bg-gray-700 capitalize cursor-pointer font-normal"
                      :class="`${option.estado === 'INACTIVO' ? 'bg-red-700' : 'bg-green-700'}`"
                    />
                  </div>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>

        <!-- Estado -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-wrap justify-between">
            <label for="status" class="block text-xs font-medium text-gray-700 mb-2">
              Estado:
              <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Dropdown
              id="status"
              v-model="_viewEditInfoInventory[0].status"
              :options="_listStatus"
              optionLabel="statusname"
              optionValue="id"
              placeholder="Seleccione una opción"
              :filter="true"
              scrollHeight="300px"
              filterPlaceholder="Buscar por nombre"
              autoFilterFocus
              class="w-full"
              :class="{ 'border-red-600': formSubmitted && !_viewEditInfoInventory[0].status }"
              :pt="{
                root: { style: 'height: 32px' },
                input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
              }"
            >
              <template #option="{ option }">
                <div class="flex items-center">
                  <Icon :icon="option.icon" class="text-3xl mr-2 opacity-40" />
                  <div>
                    <div class="font-medium">{{ option.statusname }}</div>
                  </div>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>

        <!-- Dueño -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-wrap justify-between">
            <label for="owner" class="block text-xs font-medium text-gray-700 mb-2">
              Dueño:
              <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Dropdown
              id="owner"
              v-model="_viewEditInfoInventory[0].owner"
              :options="_listOwners"
              optionLabel="ownername"
              optionValue="id"
              placeholder="Seleccione una opción"
              :filter="true"
              scrollHeight="300px"
              filterPlaceholder="Buscar por nombre"
              autoFilterFocus
              class="w-full"
              :class="{ 'border-red-600': formSubmitted && !_viewEditInfoInventory[0].owner }"
              :pt="{
                root: { style: 'height: 32px' },
                input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
              }"
            />
          </div>
        </div>

        <!-- Atributos del registro -->
        <div class="col-span-12 lg:col-span-12">
          <Divider
            align="left"
            type="dashed"
            :pt="{
              root: { style: 'margin: 7px 0 7px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-sm font-semibold cursor-pointer text-blue-900">Atributos del registro</span>
          </Divider>
        </div>

        <div v-for="field of _viewEditInfoInventory[0].fieldsAttributes" :key="field.nameField" class="col-span-12 lg:col-span-12">
          <label :for="field.nameField" class="block text-xs font-medium text-gray-700 mb-2">
            {{ field.showName }}:
            <span v-if="field.isRequired" class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            <span v-if="field.isUnique" class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo único' }">(u)</span>
          </label>
          <InputText
            v-if="field.dataType === 'text'"
            type="text"
            v-model="field.value"
            size="small"
            class="w-full"
            :pt="{
              root: { style: 'height: 2rem; font-size: 0.875rem' }
            }"
          />
          <InputNumber
            v-else-if="field.dataType === 'number'"
            v-model="field.value"
            class="w-full"
            :mode="field.numberFormat === 'currency' ? 'currency' : 'decimal'"
            :currency="`COP`"
            :locale="`es-CO`"
            :useGrouping="field.numberFormat !== 'integer'"
            :maxFractionDigits="field.numberFormat === 'decimal' ? 1 : 0"
            :suffix="field.numberFormat === 'percent' ? '%' : ''"
            :pt="{
              root:{ style: 'height: 2rem' },
              input: { style: 'width:100%; font-size: 0.875rem;' }
            }"
          />
          <Textarea
            v-else-if="field.dataType === 'textarea'"
            v-model="field.value"
            rows="3"
            cols="20"
            class="w-full"
          />
          <InputText
            v-else-if="field.dataType === 'date'"
            type="date"
            v-model="field.value"
            size="small"
            class="w-full"
            :pt="{
              root: { style: 'height: 2rem' },
              input: { style: 'font-size: 0.875rem' },
              panel: { style: 'font-size: 0.875rem' }
            }"
          />
          <InputText
            v-else-if="field.dataType === 'email'"
            type="email"
            v-model="field.value"
            size="small"
            class="w-full"
            placeholder="example@domain.com"
            :pt="{
              root: { style: 'height: 2rem' },
              input: { style: 'font-size: 0.875rem' },
              panel: { style: 'font-size: 0.875rem' }
            }"
          />
          <Dropdown
            v-else-if="field.dataType === 'select'"
            v-model="field.value[0]"
            :options="field.choiceList"
            optionLabel="label"
            optionValue="value"
            placeholder="Seleccione una opción"
            :filter="true"
            scrollHeight="300px"
            filterPlaceholder="Buscar por nombre"
            autoFilterFocus
            class="text-xs w-full"
            inputClass="w-full"
            :pt="{
              root: { style: 'height: 2rem' },
              input: { style: 'font-size: 0.875rem' },
              panel: { style: 'font-size: 0.875rem' }
            }"
          />
          <InputSwitch
            v-else-if="field.dataType === 'boolean'"
            v-model="field.value"
          />
          <div v-else-if="field.dataType === 'checkbox'">
            <div v-for="(category, index) in field.choiceList" :key="index" class="flex items-center mb-2 gap-2">
              <Checkbox v-model="field.value" :inputId="category.value" :name="field.nameField" :value="category.value" />
              <label :for="category.value">{{ category.label }}</label>
            </div>
          </div>

          <div v-else-if="field.dataType === 'radio'">
            <div v-for="(category, index) in field.choiceList" :key="index" class="flex items-center mb-2 gap-2">
              <RadioButton v-model="field.value" :inputId="category.value" :name="field.nameField" :value="category.value" />
              <label :for="category.value">{{ category.label }}</label>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<style scoped>

</style>
