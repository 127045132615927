import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_INVENTARIO_TI

export default class BrandsService {
  // Obtener las marcas
  getBrands (params) {
    return http.get(`${baseUrl}/brand/listar-marcas`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  // Crear una marca
  createBrand (body) {
    return http.post(`${baseUrl}/brand/crear-marca`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Editar una marca
  editBrand (id, body) {
    return http.put(`${baseUrl}/brand/editar-marca/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Eliminar una marca
  deleteBrand (id) {
    return http.delete(`${baseUrl}/brand/eliminar-marca/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
