<script setup>
import { computed, defineProps, ref } from 'vue'
import { useStore } from '@/store'
import InventoryService from '@/apps/dailyTech/inventario-ti/services/inventory.service'
import Icon from '@/components/Icon/index.vue'

const store = useStore()
const _InventoryService = ref(new InventoryService())

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  column: {
    type: String,
    default: ''
  }
})

// Obtener el valor de la columna
const photo = computed(() => {
  return props.data[props.column]
})

// Obtener la imagen del registro inventario
const getImageInventory = (image) => {
  const token = store.getters['auth/getToken']
  const obj = { image, token }
  return _InventoryService.value.getImageInventory(obj)
}
</script>

<template>
  <Image
    v-if="photo"
    :src="getImageInventory(photo)"
    alt="Image"
    preview
    :pt="{
      root: { class: 'flex justify-center items-center w-10 h-10 bg-gray-200 rounded-md' },
      image: { style: 'width: 35px; height: 35px; object-fit: contain;' }
    }"
  />
  <div v-else class="flex justify-center items-center w-10 h-10 bg-gray-200 rounded-md opacity-50">
    <Icon icon="material-symbols-light:no-photography" class="text-2xl" />
  </div>
</template>

<style scoped>

</style>
