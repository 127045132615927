import http from '@/libs/http'
import { useStore } from '@/store'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_INVENTARIO_TI

export default class InventoryService {
  // Crear registro de inventario
  createInventory (body) {
    return http.post(`${baseUrl}/inventory/crear`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Obtener el listado de registro de inventario
  getListInventory (params) {
    return http.get(`${baseUrl}/inventory/listar-inventario`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  // Ver imagen
  getImageInventory (params) {
    const { image, token } = params
    return `${baseUrl}/inventory/ver-imagen/${image}?token=${token}`
  }

  // Buscar registro de inventario
  findInventory (id) {
    return http.get(`${baseUrl}/inventory/buscar/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  // Editar registro de inventario
  editInventory (id, body) {
    return http.put(`${baseUrl}/inventory/editar/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Exportar a excel
  exportExcelInventory (params) {
    const store = useStore()
    return new Promise((resolve) => {
      const url = `${baseUrl}/inventory/exportar-excel`
      const token = store.getters['auth/getToken']
      const res = http.getUri({ url, params: { token, ...params } })
      resolve(res)
    })
  }

  // Descargar plantilla excel
  downloadTemplate () {
    const store = useStore()
    return new Promise((resolve) => {
      const url = `${baseUrl}/inventory/descargar-plantilla-excel`
      const token = store.getters['auth/getToken']
      const res = http.getUri({ url, params: { token } })
      resolve(res)
    })
  }

  // Validar la existencia de las columnas en la plantilla
  validateColumnsTemplate (data) {
    return http.post(`${baseUrl}/inventory/validar-columnas`, data, {
      headers: {
        loading: true
      }
    })
  }

  // Importar inventario
  importInventory (body) {
    return http.post(`${baseUrl}/inventory/importar-masivo`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Obtener el listado de actividad de logs por el ID del registro de inventario
  getLogsActivityInventory (recordId, params) {
    return http.get(`${baseUrl}/logs/buscar-inventario/${recordId}`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  // Eliminar registro de inventario
  deleteInventory (id, params) {
    return http.delete(`${baseUrl}/inventory/eliminar-inventario/${id}`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
