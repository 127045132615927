<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  column: {
    type: String,
    default: ''
  }
})

const valueColumn = computed(() => {
  return props.data[props.column]
})

</script>

<template>
  <span>{{ valueColumn }}</span>
</template>

<style scoped>

</style>
