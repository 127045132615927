import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_INVENTARIO_TI

export default class OwnersService {
  // Obtener los dueños
  getOwners (params) {
    return http.get(`${baseUrl}/owner/listar-owners`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  // Crear un dueño
  createOwner (body) {
    return http.post(`${baseUrl}/owner/crear-owner`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Editar un dueño
  editOwner (id, body) {
    return http.put(`${baseUrl}/owner/editar-owner/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Eliminar un dueño
  deleteOwner (id) {
    return http.delete(`${baseUrl}/owner/eliminar-owner/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
